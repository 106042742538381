import { createRouter, createWebHashHistory } from "vue-router";
// import Home from '../views/Home.vue'
// import searchProject from '../views/projectManage/searchProject.vue'

const routes = [
  {
    path: "/disease",
    name: "疾病名管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/disease/search.vue"),
  },
  {
    path: "/syndrome",
    name: "症候群名管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/syndrome/search.vue"),
  },
  {
    path: "/prescription",
    name: "处方管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/prescription/search.vue"),
  },
  {
    path: "/medicine",
    name: "药名管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/medicine/search.vue"),
  },
  {
    path: "/role",
    name: "角色管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/role/search.vue"),
  },
  {
    path: "/permission",
    name: "权限管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/permission/search.vue"),
  },
  {
    path: "/menu",
    name: "菜单管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/menu/search.vue"),
  },
  {
    path: "/group",
    name: "用户组管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/group/search.vue"),
  },
  {
    path: "/module",
    name: "模块管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/module/search.vue"),
  },
  {
    path: "/club",
    name: "门店菜单管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/club/search.vue"),
  },
  {
    path: "/consultation",
    name: "就诊一览",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/consultation/search.vue"),
  },
  {
    path: "/product",
    name: "药品管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/product/search.vue"),
  },
  {
    path: "/task",
    name: "批量任务管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/task/search.vue"),
  },
  {
    path: "/member",
    name: "员工管理",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/member/search.vue"),
  },
  {
    path: "/monthlyReports",
    name: "汇总报表",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/monthlyReports/search.vue"),
  },
  {
    path: "/listReports",
    name: "清单报表",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/listReports/search.vue"),
  },
  {
    path: "/performanceVerification",
    name: "业绩核对",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/performanceVerification/search.vue"),
  },
  {
    path: "/innerStudy",
    name: "自学习结果统计",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/innerStudy/search.vue"),
  },
  {
    path: "/usageHabit",
    name: "使用习惯统计",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/usageHabit/search.vue"),
  },
  {
    path: "/test",
    name: "测试",
    meta: {
      keepAlive: true,
      limite: "可编辑",
    },
    component: () => import("/src/views/test/search.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export default router;
