<template>
  <div class="bg">
    <div class="loginBox">
      <div class="imgArea">
        <img src="../../assets/imgs/logo.jpg" alt />
        <p>伏病传承工作室后台系统</p>
      </div>

      <div class="loginArea">
        <p style="font-size: 18px">
          <span style="margin-right: 10px">用户登录</span>
          <br />
          <span style="font-weight: 400; font-size: 14px; color: #999999">USER LOGIN</span>
        </p>
        <a-input v-model:value="username" style="margin-top: 60px">
          <template #prefix>
            <svg t="1653637213377" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2870" width="25" height="25">
              <path d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667z m0 160c70.4 0 128 57.6 128 128s-57.6 128-128 128-128-57.6-128-128 57.6-128 128-128z m236.8 507.733333c-23.466667 32-117.333333 100.266667-236.8 100.266667s-213.333333-68.266667-236.8-100.266667c-8.533333-10.666667-10.666667-21.333333-8.533333-32 29.866667-110.933333 130.133333-187.733333 245.333333-187.733333s215.466667 76.8 245.333333 187.733333c2.133333 10.666667 0 21.333333-8.533333 32z" p-id="2871" fill="#CCCCCC" />
            </svg>
          </template>
        </a-input>
        <a-input-password v-model:value="password">
          <template #prefix>
            <svg t="1653637700869" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4050" width="18" height="18" style="margin-right: 7px">
              <path d="M704 0c-176.736 0-320 143.264-320 320 0 20.032 1.856 39.616 5.376 58.624l-389.376 389.376 0 192c0 35.36 28.64 64 64 64l64 0 0-64 128 0 0-128 128 0 0-128 128 0 83.04-83.04c34.016 12.32 70.688 19.04 108.96 19.04 176.736 0 320-143.264 320-320s-143.264-320-320-320zM799.872 320.128c-53.024 0-96-42.976-96-96s42.976-96 96-96 96 42.976 96 96-42.976 96-96 96z" p-id="4051" fill="#CCCCCC" />
            </svg>
          </template>
        </a-input-password>
        <div class="forget">
          <!-- <a-checkbox v-model:checked="checked">记住密码</a-checkbox> -->
          <div>
            <!-- <a  @click="reg">注册账号</a>
            <span>丨</span>-->
            <!-- <a>忘记密码</a> -->
          </div>
        </div>
        <!-- <div class="reg">
          <a @click="reg"
            >租赁管理单位注册 <RightOutlined style="margin-left: 5px"
          /></a>
        </div>-->
        <a-button type="primary" block @click="login">
          <div class="loginBtn">登录</div>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { RightOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    RightOutlined
  },
  data() {
    return {
      username: "",
      password: "",
      checked: false,
      ISFIRST: undefined
    };
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
  },
  methods: {
    login() {
      let _this = this
      this.$store.commit("getUserInfo", { username: this.username, password: this.password, clientAppName: "智慧中医" })

    },
    handleKeydown(event) {
      if (event.keyCode === 13) {
        this.login()
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
};
</script>

<style scoped>
.bg {
  background: url(../../assets/imgs/bg.svg) no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(236, 239, 244, 0.996078431372549);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 1361px 609px;
  background-origin: border-box;
}

.loginBox {
  display: flex;
  width: 800px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
  font-family: "微软雅黑", sans-serif;
}
.imgArea {
  position: relative;
  width: 330px;
  height: 100%;
  background: url(../../assets/imgs/login.svg);
}
.imgArea img {
  width: 70px;
  height: 72px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  /* border-radius: 50%; */
}
.imgArea p {
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
}
.loginArea {
  width: 470px;
  padding: 35px 50px;
  position: relative;
}
.loginArea p span {
  color: #666666;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
  font-weight: 700;
}

.ant-input-affix-wrapper {
  width: 100%;
  height: 45px;
  padding: 10px;
  margin-bottom: 30px;
}

.forget {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
}

.loginBtn {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
}

.ant-btn {
  padding: 10px 0;
  height: auto;
  border-radius: 5px;
  margin-top: 60px;
}

.reg {
  position: absolute;
  top: 40px;
  right: 50px;
}
</style>
